export default{
    setRitten(state, payload){
        state.ritten = payload;
    },
    setLoading(state, payload){
        state.loading = payload;
    },

    setConfirmRitten(state, payload){
        state.confirmRitten = payload;
    }
}