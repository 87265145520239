<template>
<div class="nav">
  <router-link :class="{'nav__button--active':$route.name==='Schedule'}" to="/" class="nav__button"><span class="material-icons">departure_board</span></router-link>
  <router-link :class="{'nav__button--active':$route.name==='Settings'}" to="/settings" class="nav__button"><span class="material-icons">manage_accounts</span></router-link>
</div>
</template>

<script>
export default {
  name: "Navigation"
}
</script>