export default {
    ritten(state) {
        return state.ritten;
    },
    rittenToDo(state) {
        return state.ritten.filter(r => r.stops && r.stops.some(stop => stop.substatus !== 'aangekomen'));
    },
    rittenDone(state) {
        const ritten = [...state.ritten];
        return ritten.filter(r => r.stops && !r.stops.some(stop => stop.substatus !== 'aangekomen')).reverse();
    },
    confirmRitten(state) {
        return state.confirmRitten;
    },
    loading(state) {
        return state.loading;
    }
}