<template>
  <div class="footer">
    <a v-if="tel" class="button" :href="tel"><span class="material-icons">phone</span> {{ $t('dispatch') }}</a>
  </div>
</template>

<script>
export default {
  name: "Footer",

  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    tel(){
      const ud = this.userData;
      if(!ud?.bedrijf){
        return null;
      }
      else if(+ud.bedrijf === 1){
        return 'tel:+33149401140'
      }
      else if(+ud.bedrijf === 2){
        return 'tel:+37797978877'
      }
      else{
        return null;
      }
    }
  }
}
</script>