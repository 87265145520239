<template>
  <div class="page home" v-if="userData">
    <h1>{{ userData.voornaam }} {{userData.familienaam}}</h1>
    <div class="page__content">
        <p>{{$t('status')}}</p>
        <div class="home__container" v-if="userData">
          <div class="home__block home__block--tasks" @click="$router.push('/schedule')">
            <div class="home__block__status">{{ ritten.length }}</div>
            <p class="home__block__text">{{$t('schedule.missions')}}</p>
          </div>
          <div class="home__block home__block--account" @click="$router.push('/settings')">
            <template v-if="vervalError">
              <span class="home__block__status material-icons error">error</span>
              <p class="home__block__text">
                {{vervalError==='vervallen'?$t('home.medic_expired'):vervalError==='onbekend'?$t('home.medic_missing'):$t('home.medic_expiring')}} <b>{{ userData.vervaldagmedischattest }}</b></p>
            </template>
            <template v-else>
              <span class="home__block__status material-icons success">checkmark</span>
              <p class="home__block__text">{{$t('home.medic_OK')}}</p>
            </template>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data(){
    return {
      CHECK_TIME: 5 * 60 * 1000,
      checkTimeout: null,
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.visibilityChanged);
    this.checkData();
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.visibilityChanged);
    clearTimeout(this.checkTimeout);
  },
  computed: {
    ritten() {
      return this.$store.getters['ritten/ritten'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    logos(){
      return this.$store.getters['user/logos'];
    },
    vervalError() {
      if (this.userData && this.userData.vervaldagmedischattest.trim()) {
        const date = this.userData.vervaldagmedischattest;
        if(!date){
          return 'onbekend';
        }
        const dateparts = date.split('/');
        const orgDate = `${dateparts[2]}/${dateparts[1]}/${dateparts[0]}`;
        const vDate = new Date(orgDate);
        const nDate = new Date();
        vDate.setHours(0, 0, 0);
        nDate.setHours(0, 0, 0);
        const diffTime = vDate - nDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 60?diffDays<=0?'vervallen':'geldig':false;
      }
      else if(this.userData){
        return 'onbekend';
      }
      else {
        return false;
      }
    }
  },
  methods: {
    visibilityChanged() {
      if (document.visibilityState === 'visible') {
        console.log('Visible');
        this.$store.dispatch('user/getUserData');
        this.checkData();
      }
      else if(document.visibilityState === 'hidden') {
        console.log('Hidden');
        clearTimeout(this.checkTimeout);
      }
    },
    checkData(){
      //this.$store.dispatch('ritten/getConfirmRitten');
      this.$store.dispatch('ritten/getRitten');
      this.checkTimeout = setTimeout(()=> {
      }, this.CHECK_TIME);
    }
  }
}
</script>
