import apihelper from "../../../helpers/apihelper";

export default{
    async getUserData(context) {
        const token = context.rootGetters.token;
        const username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/selectprofile?username=${username}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user data or access denied.'
        });
        context.commit('setUserData', {
            userData: responseData.data
        });
    },

    clearUserData(context) {
        context.commit('setUserData', {
            userData: null
        });
        context.commit('setMessageToken', null);
    },

    setMessageToken(context, payload){
        context.commit('setMessageToken', payload);
    },

    async saveUserProfile(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/users/saveprofile`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to edit user data.'
        });
        await context.dispatch('getUserData');
    }
}