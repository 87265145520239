import { createStore } from 'vuex'
import authModule from './modules/auth';
import userModule from './modules/user';
import rittenModule from './modules/ritten';
export default createStore({
  modules: {
    auth: authModule,
    user: userModule,
    ritten: rittenModule
  }
})
