import apihelper from "../../../helpers/apihelper";
export default{
    async getRitten(context){
        const token = context.rootGetters.token;
        const username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/listrides?pers_id=${username}`;
        context.commit('setLoading', true);
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user ritten'
        });
        context.commit('setLoading', false);
        context.commit('setRitten', responseData.data);
    },

    async getConfirmRitten(context){
        const token = context.rootGetters.token;
        const username = context.rootGetters.userName;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/ridestoconfirm?pers_id=${username}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user ritten'
        });
        context.commit('setConfirmRitten', responseData.data);
    },

    async toggleConfirm(context, {id, bevestigd}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/confirmride/${id}?bevestigd=${bevestigd}`;
        await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to edit status mission'
        });
    },

    async getRitTimetable(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/timetable/${id}`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch user ritten'
        });
        return responseData.data;
    },

    clearRitten(context) {
        context.commit('setRitten', []);
    },

    async updateState(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/updatestatus`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to update status.'
        });
        await context.dispatch('getRitten');
    },

    async cancelState(context,payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/cancelstatus`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to cancel status.'
        });
        await context.dispatch('getRitten');
    },

    async saveDriverInput(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/rides/savedriverinput`;
        await apihelper.fetchPostRequest({
            url,
            token,
            payload: JSON.stringify(payload),
            errorMsg: 'Failed to register input.'
        });
        await context.dispatch('getRitten');
    },

    async generateCardex(context, {id}){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/preview/${id}?template=cardex`;
        const responseData = await apihelper.fetchGetRequest({
            url,
            token,
            errorMsg: 'Failed to fetch cardex.'
        });
        return responseData.data;
    },
}