<template>
  <Navigation v-if="isAuthenticated"/>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <keep-alive include="Schedule">
        <component :is="Component"/>
      </keep-alive>
    </transition>
  </router-view>
  <TheFooter/>
  <transition name="bottom-slide" mode="out-in">
    <div class="notification" v-if="updateExists">
      <span>Update available</span>
      <button class="icon-button" @click="refreshApp"><span class="material-icons">refresh</span></button>
    </div>
  </transition>
  <transition name="bottom-slide" mode="out-in">
    <Notification v-if="activeNotification" :notification="activeNotification" @on-close="activeNotification=null"/>
  </transition>
</template>

<script>
//import firebaseMessaging from './firebase';
//import {getToken, onMessage} from "firebase/messaging";
import update from "./mixins/update";
import Navigation from "./components/Navigation";
import Notification from "@/components/Notification";
import TheFooter from "./components/TheFooter";

export default {
  mixins: [update],
  components: {
    Navigation,
    TheFooter,
    Notification
  },
  data(){
    return {
      activeNotification: null
    }
  },
  created() {
    this.tryLogin();
   // this.initMessaging();
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated && this.$store.getters['user/userData'];
    },
    userLang() {
      return this.$store.getters['user/userData']?.taal;
    }
  },

  watch: {
    userLang() {
      if (this.userLang) {
        this.$i18n.locale = this.userLang.toLowerCase();
      }
    }
  },

  methods: {
    async tryLogin() {
      try {
        await this.$store.dispatch('tryLogin');
      } catch (e) {
        alert(e);
      }
    },
    //initMessaging() {
    //  const messaging = firebaseMessaging;
    //  getToken(messaging,{vapidKey: 'BCBbn-iiX2W_o61jP0Wet_2wO18qc8Mp-i9jX5ZDMUY_J7mV5c7IMPROqiiBUNeVHMwyUwZ9um9PgDkinHT_UhU'})
    //      .then(token => {
    //        this.$store.dispatch('user/setMessageToken', token);
    //      })
    //      .catch((err) => {
    //        console.log("Unable to get permission to notify.", err);
    //      });
//
    //  onMessage(messaging, (payload) => {
    //    console.log('Foreground Message received. ', payload);
    //    this.activeNotification = payload;
    //  });
    //}
  }
}
</script>
<style lang="scss">
@import 'assets/scss/app';

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  left: 60%;
  opacity: 0;
}

.bottom-slide-enter-active, .bottom-slide-leave-active {
  transition: all 0.3s ease-out;
}

.bottom-slide-enter-from, .bottom-slide-leave-to {
  opacity: 0;
  bottom: -4rem;
}

.top-slide-enter-active, .top-slide-leave-active {
  transition: all 0.3s ease-out;
}

.top-slide-enter-from, .top-slide-leave-to {
  opacity: 0;
  top: -4rem;
}
</style>
