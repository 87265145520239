export default {
    userData(state){
        return state.userData;
    },
    logos(state){
        return state.logos;
    },
    messageToken(state){
        return state.messageToken;
    }
};