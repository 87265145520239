export default {
    setUserData(state, payload){
        state.userData = payload.userData;
    },
    setLogos(state, payload){
        state.logos = payload;
    },
    setMessageToken(state, payload) {
        state.messageToken = payload;
    }
};